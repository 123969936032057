<script lang="ts" setup>
import { Icon } from "@iconify/vue";

const props = defineProps<{
  date?: string | null;
  user?: string;
  icon: string;
  minimized?: boolean;
  tooltip?: string;
}>();
</script>

<template>
  <BasicTooltip :disabled="!tooltip" side="left">
    <template #trigger>
      <div
        v-if="date"
        :class="
          cn('text-neutral-5 text-xs tracking-wide flex items-center gap-1.5 leading-[1]', {
            'text-sm gap-2': !props.minimized
          })
        "
      >
        <Icon :icon="icon" :class="cn('text-neutral-5 shrink-0', {})" />
        <p>
          <Date :date="date" />
          <span v-if="user">
            {{ " " }}{{ $t("createdUpdated.by") }}
            {{ user }}
          </span>
        </p>
      </div>
    </template>
    <template v-if="tooltip" #content>
      {{ $t(tooltip) }}
    </template>
  </BasicTooltip>
</template>
