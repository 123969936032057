<script lang="ts" setup>
import Element from "./Element.vue";

const props = defineProps<{
  createdAt?: string | null;
  createdByUser?: {
    username: string;
  } | null;
  lastUpdate?: {
    date: string;
    user: {
      username: string;
      id: string;
    };
  }[];
  class?: string;
  minimized?: boolean;
}>();

const lastUpdate = computed(() => {
  if (!props.lastUpdate) return { date: null, user: null };
  return {
    date: props.lastUpdate?.length ? props.lastUpdate[0].date : null,
    user: props.lastUpdate?.length ? props.lastUpdate[0].user : null
  };
});
</script>

<template>
  <div :class="cn('grid', props.class)">
    <Element
      :date="createdAt"
      :user="createdByUser?.username"
      :minimized="minimized"
      tooltip="createdUpdated.created"
      :icon="ICONS.submissions"
    />
    <Element
      :date="lastUpdate.date"
      :user="lastUpdate.user?.username"
      :minimized="minimized"
      tooltip="createdUpdated.updated"
      :icon="ICONS.accepted"
    />
  </div>
</template>
